/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */

body {
  /* font-family: "lohit-punjabi"; */
  src: url("https://candyfonts.com/wp-data/2019/03/05/30372/lohit.punjabi.1.1.ttf") format("woff"),
    url("https://candyfonts.com/wp-data/2019/03/05/30372/lohit.punjabi.1.1.ttf") format("opentype"),
    url("https://candyfonts.com/wp-data/2019/03/05/30372/lohit.punjabi.1.1.ttf") format("truetype");

  }


.img-fluid-log{
  max-width: 30% !important;
}
.heading_18{
  font-size: 18px;
    color: #040c29;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 5px !important;
    display: block;
}
.heading_16 {
  font-size: 16px;
  color: #040c29;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 5px !important;
  display: block;
}

.link-like {
  color: #0000ff9e; /* Text color */
  text-decoration: underline; /* Underline text */
  cursor: pointer; /* Pointer cursor to indicate clickable */
  display: inline-flex; /* Align text and image horizontally */
  align-items: center; /* Vertically center the image and text */
}


.link-like:hover {
  color: rgb(81, 81, 242); /* Change color on hover */
}
.description{
  color: #767c90;
    font-size: 14px;
}
.description_wish{
  color: #000000;
    font-size: 20px;
}

.font-500{
  font-weight: 500;
}

.font-600{
  font-weight: 600;
}
h4{
  font-size: 20px;
}

/* new records */
.as_zodiac_single_wrapper {
  background-color: #f2f2f2;
  color: #000000;
  /* margin-top: 73px; */
}

/* .as_zodiac_single_wrapper .astrologer_heading {
  padding-top: 95px;
} */

.as_zodiac_single_wrapper .astrologer_type .nav .nav-item a {
  padding: 15px 15px;
  color: #767c90;
  font-size: 18px;
  cursor: pointer;
}

.as_zodiac_single_wrapper .astrologer_type .nav .nav-item a.active {
  color: #886459;
  border-bottom: 2px solid #886459;
  font-weight: 500;
}

.as_zodiac_single_wrapper .border_bottom {
  border-bottom: 1px solid #b9bbbd;
  padding-bottom: 0;
}

.feed_comments_sec>ul>li p {
  font-size: 13px;
  color: #040c29;
  font-weight: 600;
  margin-bottom: 0;
}

/* detail btn */

.detail_btn {
  font-size: 11px;
  line-height: 12px;
  margin-top: 0;
  padding: 5px 0px;
  font-weight: 700;
  color: #2270e8;
  letter-spacing: 1px;
  border-radius: 5px;
  background-color: transparent !important;
  text-decoration: underline;
  border: none;
}
.detail_btn:hover{
  background-color: transparent !important;
}
.chadhawa_manokamna_details_para {
  font-size: 15px;
  margin-top: -6px;
}

/* no record */
.no_records{
  text-align: center;
  margin-top: 50px;
  font-size: 25px;
}

.feed_comments_sec>ul>li a.reply {
  font-size: 12px !important;
  color: #767c90 !important;
  display: inline-block;
}

.feed_comments_sec ul li {
  margin: 0 !important;
}

.feed_comments_sec>ul>li {
  border-bottom: 1px solid #979ba740;
  padding: 10px 0;
}

.feed_comments_sec ul.fcs_reply_sec li h4 {
  color: #040c29;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
  font-family: 'Inter', sans-serif;
}

.feed_comments_sec ul.fcs_reply_sec li p {
  font-size: 12px;
  color: #767c90;
  margin-bottom: 0;
  font-weight: 500;
}

.feed_comments_sec ul.fcs_reply_sec li .des.d-flex p {
  font-size: 13px;
  color: #040c29;
  font-weight: 600;
  margin-bottom: 0;
  width: 95%;
}

.feed_comments_sec ul.fcs_reply_sec li .user_info h5 {
  color: #767c90;
  font-size: 11px;
  font-style: italic;
}

.feed_comments_sec ul.fcs_reply_sec {
  width: 100%;
  background: #f0f0f0;
  padding: 10px !important;
  border-radius: 6px;
  margin-top: 10px !important;
}

.feed_comments_sec ul.fcs_reply_sec li {
  margin-right: 0 !important;
}

.feed_comments_sec i.fa.fa-ellipsis-h {
  color: #979ba7;
}

.feed_comments_sec .reply_input_sec {
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-radius: 30px;
}

.feed_comments_sec .reply_input_sec input {
  background: transparent;
  border: none;
  font-size: 12px;
  color: #040c29;
  font-weight: 600;
  width: 90%;
}

.feed_comments_sec .reply_input_sec input::placeholder {
  color: #040c29;
}


.feed_comments_sec .reply_input_sec button.btn {
  padding: 0;
  font-size: 12px;
  color: #4b3b5a;
  font-weight: 600;
}

.astro_feed_sec .feed_comments_sec ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.astro_feed_sec .feed_comments_sec ul .card-header {
  background: transparent;
  border: none;
  padding: 0;
  padding-bottom: 0;
}

.astrologer_feed_area {
  height: 90vh;
}


.form-control:focus {
  /* color: #ffffff; */
  background-color: transparent;
  outline: 0;
  box-shadow: none;
  /* border-bottom: 1px solid #b3b3b3; */
}

.as_login_area .form-control {
  width: 100%;
  height: 50px;
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none !important;
  color: #ffffff;
  padding: 0 20px 0 20px;
  border-bottom: 1px solid #ffffff;
  border-radius: 6px;
  margin-bottom: 25px;
  position: relative;
}

.as_login_area  .form-control::placeholder {
  color: #ffffff;
}

button.as_btn {
  height: 40px;
  background-color: #e8ba22;
  font-size: 13px;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  /* padding: 0 23px; */
  position: relative;
  margin-left: 15px;
  border: none!important;
  outline: none;
  width: auto;
  transition: .2s linear;
  border-radius: 6px;
  color: #ffffff;
  letter-spacing: 0.5px;
  font-weight: 500;
}

.header {
  border-bottom: none;
  background-color: #25658f;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0,0,0,.2);
  background-image: linear-gradient(343deg,#b2373b,#970625);
}

.navbar-light .navbar-nav .nav-link {
 color: white !important;
  font-size: 14px;
}

.header .active {
  background: #4b8ab5;
  border-radius: 6px;
}



.bar1 {
  border: 1px solid;
  width: 50%;
  /* margin: 10px;
  align-items: center;
  padding: 5px;
  margin-left: 200px; */
  float: left;

}

.p1 {
  border: 1px solid;
  width: 10%;
  padding: 10px;
  margin: 10px;
  display: inline-block;
  background-color: #FBEAFF;
  border: hidden;
}

.p1:hover {
  background-color: #845EC2;
  color: white;
}

.p2 {
  border: 1px solid;
  width: 15%;
  padding: 10px;
  margin: 10px;
  display: inline-block;
  background-color: #FBEAFF;
  border: hidden;
}

.p2:hover {
  background-color: #845EC2;
  color: white;
}

.p3 {
  border: 1px solid;
  width: 10%;
  padding: 10px;
  margin: 10px;
  display: inline-block;
  background-color: #FBEAFF;
  border: hidden;
}

.p3:hover {
  background-color: #845EC2;
  color: white;
}

/* FacebookPost */

.cardd {
  width: 30.33%;
  float: left;
  margin: 15px;
}

.card-body {
  /* width: 336px;
  height: 455px; */
  text-align: justify;
  /* margin-left: 37px; */

  height: 100%;
  overflow: hidden;
}

.card-img-top {
  /* width: 47%; */
  max-width: 256;
  min-width: 120px;
  height: 300px;
  /* margin-left: 100px; */
  margin-bottom: 15px;
}

.btn {
  color: #040404;
  background-color: #dcdcdc;
  border: none;
  --bs-btn-padding-y: 0.2rem !important;
  margin-top: 10px;
}

.btn:hover {
  /* color: var(--bs-btn-hover-color); */
  color: #040404 !important;
  background-color: #deeef9 !important;
  border-color: var(--bs-btn-hover-border-color);
}

.nav-link {
  margin-left: 10px;
}

.imgclass {
  width: 47%;
  max-width: 256px;
  min-width: 120px;
  height: 249px;
  margin-left: -14px;
  margin-top: 10px;
  margin-bottom: 6px;
}


.dailycardbody {
  width: 95%;
  height: 420px;
  text-align: justify;
  margin-left: 30px;
}

.shadow {
  margin-bottom: 29px;
  /* border-radius: 45px; */
}

.headerdd {
  font-size: 1.75rem;
  margin-bottom: 17px;
  margin-top: 18px;

}

.weeklycardbody {
  width: 95%;
  height: 350px;
  text-align: justify;
  margin-left: 30px;
}

.monthlycardbody {
  width: 95%;
  height: 600px;
  text-align: justify;
  margin-left: 30px;
}

.homepage {
  background-position: 100% 50%;
  background-size: cover;
}

.imgastro {
  width: 280px;
  float: left;
  /* margin-left: 23px;
  margin-top: 17px; */
  margin-left: 540px;
  margin-top: 249px;
}

.btnhome {
  color: #212529;
  background-color: #e9ecef3d;
  border-color: #2125298c;
  float: left;
  margin-top: 200px;
  margin-left: -63px;
  border-radius: 6px;
  padding: 8px;
  width: 152px;
}

#bookbtn {
  position: absolute;
  bottom: 28px;
  margin-left: 82px;
}

.festivalcardd {
  /* width: 33.33%; */
  /* float: left; */
  margin: 15px;
}

.blogbody {
  height: 620px;
}

.festivalcardbody {
  height: 500px;
}

/*login_page_css_start*/
.form-group {
  text-align: initial;
}

.form-group {
  text-align: initial;
  margin: 10px 0;
}

span.shownpwd {
  color: #ea6b24;
  cursor: pointer;
  font-size: 20px;
  padding-right: 21px;
  z-index: 99;
  position: absolute;
  right: 0px;
  top: 11px;
}


.as_login_area {
  background-attachment: fixed;
  width: 100%;
  min-height: calc(100vh - 70px);
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(150deg, #b2147b, #b24b14);
  background-size: cover;
}

.as_login_area .login-shape {
  position: absolute;
  bottom: -90px;
  left: -90px;
}

.as_login_area .login_inner {
  width: 650px;
  margin: auto;
  padding: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgb(255 255 255 / 20%);
  background: rgba(255,255,255,0.2);
  height: auto;
  align-items: center;
  justify-content: center;
  border-radius:6px;
   position: absolute;
  /* backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);  */
  align-self: flex-center;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  text-align: center;
}

.as_login_area .login_logo img {
  width: 230px;
  margin-bottom: 30px;
  object-fit: contain;
  margin-top: 30px;
}

.as_login_area .login_logo {
  text-align: center;
}

/* .as_login_area .form-control {
  height: 50px;
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none !important;
  color: #000000;
  padding: 0 20px 0 20px;
  border-bottom: 1px solid #221c4a;
  border-radius: 0;

} */

.as_login_area .login_form {
  padding: 0;
}

/*login_page_css_end*/
/* header css start */
.as_header_wrapper {
  display: flex;
  background-color: #07273c;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 99999;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  background-image: linear-gradient(343deg, #b2373b, #970625);
  top: 0;
}

.as_header_wrapper .as_logo img {
  object-fit: contain;
}

.as_logo {
  padding: 22px 50px;
  border-right: 1px solid hsla(0, 0%, 100%, .1);
  display: flex;
  align-items: center;
  background: #fff;
}

.as_menu ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.as_menu ul li.login_btn a {
  background: #e8b922;
  padding: 5px 20px !important;
  color: #ffffff !important;
  border-radius: 6px;
  text-transform: uppercase;
  font-weight: 600;
  overflow: hidden;
  position: relative;
}

.as_menu ul li.login_btn a span {
  position: relative;
  z-index: 1;
}

.main-navbar .dropdown-menu {
  left: auto;
  right: 5px;
  margin-top: 6px;
  z-index: 1001;
}

.puja_booking-wrapper .react-datepicker-wrapper {
  display: block !important; 
}




.chat-users-list .chat-user {
  cursor: pointer;
}

.chat-form input {
  width: 90% !important;
  padding: 0px 15px !important;
}

.chat-form button {
  padding: 10px 0;
  width: 105px;
  font-size: 16px;
}


.chat-users-list .chat-user.active {
  color: #fff;
  background-image: linear-gradient(150deg, #B2147B, #B24B14);

}

.chat-users-list .chat-user:hover {
  background-color: #ced0da;
}

.chat-window {
  border: 1px solid;
}

.chat-header {
  border-bottom: 1px solid;
  padding: 10px 10px;
  background-color: #07273c;
  color: #fff;
}



.chat-history {
  padding: 10px 10px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 485px;
}

.event-pointer {
  cursor: pointer;
}
.w-1920.staticWidth{
  width: 950px;
}
.btnHover:hover {
  color: white !important;
  background-color: #e8ba22 !important;
}

.pointerEvent {
  cursor: pointer;
}

#upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.textAreaHeight {
  height: 200px !important;
}

.modalInputs {
  outline: none;
  border-radius: 6px;
}

.modalInputs:focus {
  border: 2px solid #25658f;
}

.adding-pre-wrap{
  white-space: pre-wrap !important;
}

.calLoader {
  position: absolute !important;
  display: flex;
  background-color: white;
  justify-content: center;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

#justify-tab-example-tab-content,
#justify-tab-example-tab-wishes {
  font-weight: 700;
  color: #25658f;
}

.tabs-color-class {
  font-weight: 500;
  color: #25658f;
}

.tabs-color-class:hover {
  color: #585858;
}

.thumbnail-custom-image {
  color: #25658f;
  padding: 3px 5px;
  border-radius: 6px;
  border: 2px solid #25658f;
}

.pointerUtilityClass {
  cursor: pointer;
}

.z_Index {
  z-index: 1000 !important;
}

#inputds {
  display: none;
}


.required:after {
  content: " *";
  color: red;
}
.modal-sm {
  --bs-modal-width: 400px;
  /* margin-top: 200px; */
} 

.model {
  --bs-modal-margin: 4.75rem !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-select {
  padding: 0.925rem 2.25rem 0.375rem 0.75rem !important;
}

._active {
  background-color: green;
  color: white;
  border: 2px solid green;
  padding: 2px 6px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  width: 60px;
  height: 25px;
  line-height: 15px;
}

.aarti_inner .card .card-header .icon.card-icons, .blog_area .icon.card-icons{
  padding: 0 4px 0px 6px!important;
}

.card-icons ._active, .card-icons ._inactive {
  padding: 0px 6px;
  width: auto;
  height: auto;
  line-height: 15px;
  font-size: 9px !important;
}



/* .card-header ._active, .card-header ._inactive {
  width: auto;
  height: auto;
} */


._active:hover {
  border: 2px solid rgb(3, 83, 3);
}

._inactive:hover {
  border: 2px solid brown;
}

._inactive {
  background-color: red;
  color: white;
  border: 2px solid red;
  padding: 2px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  width: 60px;
  height: 25px;
  line-height: 15px;
}




.Dt-btn {
  background-color: red;
  color: white;
  border-radius: 6px;
  font-size: 12px;
  border: none;
  padding: 0px 8px;
  font-weight: 500;
  width: auto;
  height: 25px;
}

/* .Et-btn:hover {
  border-color: #a21a2e;
}

.Dt-btn:hover {
  border-color:#000000;
} */


.Et-btn {
  background-color: orange;
  color: white;
  border-radius: 6px;
  font-size: 12px;
  border: none;
  padding: 0px 8px;
  font-weight: 500;
  width: auto;
  height: 25px;
}

.sidenav {
  height: 100%;
  width: 160px;
  position: fixed;
  z-index: 1;
  /* top: 0; */
  left: 0;
  background-color: white;
  overflow-x: hidden;
  padding-top: 20px;
  margin-top: 30px;
}

.sidenav a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  margin-top: 4px;
}

.sidenav a:hover {
  color: lightblue;
}

.activel {
  background-color: #25658f;
  color: #fff !important;
  border-radius: 6px;
  margin-left: 8px;
}

.lineThrough{
  text-decoration-line: line-through !important;
}

.main {
  margin-left: 160px;
  /* Same as the width of the sidenav */
  font-size: 28px;
  /* Increased text to enable scrolling */
  padding: 0px 10px;
}

/* 
@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
} */
.sidenav {
  padding-top: 10px;
}

.sidenav a {
  font-size: 16px;
}

#labimg {
  width: 30%;
  display: block !important;
}

#labimg input {
  display: none;
}

.hd-cr{
  cursor: pointer;
  height: 40px;
}
.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.40em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-color: #fff;
  background: #17a2b8;
}
.dropdown-menu {
  --bs-dropdown-link-active-bg:brown !important;
}
.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  gap: 30px;
  padding: 40px;
}

.grid-container2 {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 20px;
  padding: 40px;
}

.grid-container3 {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 20px;
  padding: 40px;
}

.grid-container>div {
  background-color: white;
  border: 1px solid rgb(202, 198, 198);
  border-radius: 6px;
  text-align: center;


}

.grid-container2>div {
  background-color: white;
  border: 1px solid rgb(202, 198, 198);
  border-radius: 6px;
  text-align: center;
}

.calicon {
  margin-right: 4px;
}

.newdiv {
margin: 40px;
}

#imgone {
  margin: 30px;
}

.card-body-b {
  padding: 40px !important;
  width: auto !important;
  margin-left: 0 !important;
}

.newdiv1 {
  margin: 60px 240px;
}


.card-img-top-b {
  width: 100%;
  height: 130px !important;
}


.card-img-top-i {
  width: 30% !important;

}

.modal {
  --bs-modal-width: 800px !important
}

.custom-modal-width {
  max-width: 950px;
}

.mrfloat{
  display: flex;
  justify-content: end;
  padding-right: 18px;
}

.modal-header {
  background-color: #25658f !important;
  color: white !important;
}


.success_btn {
  background-image: linear-gradient(343deg,#b2373b,#970625);
  color: white;
  height: 40px;
}
.success_btn:hover {
  color: white !important;
}
.cancle_btn {
  background-color: lightslategray !important;
  border-color: lightslategray;
  color: white;
  height: 40px;
}

.add-btn {
  background-color: lightblue;
}
.btn.show {
  color: #000000 !important;
}
.dropselect .btn{
  background-color: white !important;
}

.mrup {
  margin: 10px 0 0 0;
}

.card {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  height: 97%;
}

.card-title-p {
  text-align: center;
  font-size: medium;
}

.card-title {
  text-align: center;
}

.card-text {
  text-align: center;
  font-size: 13px;
  min-height: 50px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.card-text-wish {
  text-align: center;
  font-size: 18px;
}
.card-text-blog {
  text-align: justify;
  font-size: 13px;
  min-height: 50px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.textst {
  color: #4d4c4c;
  word-spacing: 5px;
  font-size: medium;

}

.btn-more-g{
background-color: #e8b922;
color: #ffffff;
margin-left: 2px;
font-size:13px;
}

.btn-more {
  border: none;
  background-color: white;
  color: blue;
  font-size: large;
}

.btn-more:hover {
  background-color: white !important;
  color: #f39d00 !important;

}

.cal-dat {
  text-align: center;
  font-size: 15px;
}

.by-btn {
  margin-right: 4px;
}
.by-btn-sec {
  margin-right: 12px;
  margin-left: 79%;
}
.left-q {
  margin-left: 8px;
}
.right-q {
  margin-right: 8px;
}
.borderRoute{
  border-bottom:1px solid brown;
  background-color:rgb(227, 218, 218);
  border-radius: 6px;
  padding: 8px;
}
.tab-hd-row {
  display: grid;
  grid-template-columns: auto 10% 8%;
  margin-top: 10px;

}

.containerM {
  position: relative;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 15px;
  color: rgb(144, 120, 120);
  width: 90%;
  text-align: center;
}

.topVIicon {
  position: absolute;
  top: 0;
  margin-left: 10px;
  font-size: 20px;
}

.subtitle-b {
  font-weight: bold;
}

.show_btn {
  background-color: lightblue;
  color: #040c29;
  text-align: right;
  font-size: medium;
  padding: 6px 15px;
  cursor: pointer;
}

.gallery {
  display: flex;
  overflow: auto;
  scrollbar-color: red orange;
  scrollbar-width: thin;
}

.gallery img {
  margin: 10px;
}

.tempFile {
  width: 40%;
  height: 40%;
  margin: 40px 50px;
  border-radius: 6px;
}

.managaldosh-form-style{
  width: 400px !important;
}

.spinner{
  position: relative;
  top: 50%;
  left: 50%;
}
.spinneruser{
  position: absolute;
  top: 50%;
  left: 50%;
}

.managaldosh-form-style button{
  background-color: #25658f;
  color: white;
}

.managaldosh-form-style input{
  height: 35px;
}

.managaldosh-form-style label {
  font-size: 14px !important;
  color: #7e7d7d;
  margin-bottom: 5px !important;
}
.dt-btn{
  color: red;
}

.hd-list {
  margin: 15px;
  color: #25658f;
}

.hd-list-f {
  color: #4b3b5a;
  /* margin-top: 10px; */

}

.Wc {
  width: 40%;
  padding: 50px;
  border: 1px solid rgb(193, 191, 191);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  border-radius: 6px;
  margin-top: 25px;
}

.tableWc {

  padding: 50px;
  border: 1px solid rgb(193, 191, 191);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  border-radius: 6px;
  margin-top: 25px;
}

.imglist {
  width: 75px;
}

/* ========================= */

.pagination>li {
  list-style: none;
  /* border: 0.3px solid; */
}

.pagination>li>a,
.pagination>li>span {
  float: left;
  padding: 8px 20px;
  line-height: 1.5;
  border: 1px solid #ddd;
  margin-left: -1px;
}

.pagination>li.active>a {
  color: #fff !important;
  background-color: #9d102a;
  border-color: #9d102a;
}
.pagination>li>a:hover {
  /* background-color:#25658f;
  color: white; */
  cursor: pointer;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
  margin-left: 0;
}

.pagination {
  float: right !important;
  margin-right: 20px;
 --bs-pagination-color: black !important;
 --bs-pagination-active-bg:#25658f !important;
}


.chat-search-field{
    position: relative;
}

.chat-bot-search-icon {
  position: absolute;
  left: 14px;
  top:7px;
}

.chat-search-field .form-input {
  height: 35px;
  width: 100%;
  text-indent: 30px;
  border-radius: 6px;
}

#dropdown-for-userby:hover {
  color: black !important;
  background-color: transparent !important;
}

.radio-btn-dosh-form{
  padding: 1px 2px;
  border-radius: 6px;
  border: 1px solid #25658f;
  background-color: transparent;
  border-radius: 6px !important;
}

.dosh-btns input[type="radio"]:checked + label {
  background-color: #25658f !important;
  color: white !important;
}

.dosh-btns input[type="radio"]:hover + label {
  background-color: #25658f !important;
  color: white !important;
}

.dosh-response-area{
  height: 100px;
  background-color: #07273c;
  padding: 5px;
  overflow-y: scroll;
}

.chat-context-menu{
  background-color:white;
  color: black;
  width: 180px;
  max-height: 240px;
  overflow-y: scroll;
  padding: 3px 10px;
  border:2px solid black;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  z-index: 99;
  flex-direction: column;
}

.chat-context-menu > button{
  color: black;
  background: transparent !important;
  border: none;
  text-align: left;
}

.chat-context-menu > button:hover{
  border-radius: 6px;
  background: black !important;
  color: white;
}


.label-selected-menu{
  width: 100% !important;
  height: 40px !important;
  padding: 0  10px!important;
  box-shadow: none !important;
}

.label-filter-menu{
  background-image: none !important;
  width: 100px !important;
  height: 40px !important;
  padding: 0  10px!important;
  box-shadow: none !important;
}


.label-items-header{
  color: white;
  background-color: #25658f;
  text-align: center;
  font-weight: 700;
  border-radius: 15px 50px;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color:rgb(141, 137, 137) !important;
  padding-right: calc(1.5em + 0.75rem);
  background-image: none !important;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color:black !important;
}
.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color:rgb(141, 137, 137) !important ;
}
.was-validated .form-select:valid, .form-select.is-valid {
  border-color:rgb(141, 137, 137) !important ;
}

::placeholder
{
padding-top:4px;
text-transform: capitalize;
}
.custom-pagination .pagination{
  padding: 0 !important;
  margin: 0 !important;
  width: 100% !important;
}

.custom-pagination {
  padding: 10px 0!important;
  width: 100% !important;
  position: fixed !important;
  bottom:0;
  padding-left: 10px !important;
  background-color: white;
}

.custom-pagination .pagination>li>a {
  padding: 2px 6px !important;
  float: none !important;
  font-size: 12px;
  font-weight: 700;
  margin-right: 3px !important;
  border-color: #808080;
  color: #808080;
}

.custom-pagination .pagination .previous a {
  padding: 2px 3px !important;
  color: #9c1029;
  margin: 0 3px;
  text-decoration: none;
  font-size: 12px;
  font-weight: 700;
  border-color: #9c0f29 !important;
}
.custom-pagination .pagination .next a {
  padding: 2px 3px !important;
  color: #9c1029;
  margin: 0 0px;
  text-decoration: none;
  font-size: 12px;
  font-weight: 700;
  border-color: #9c0f29 !important;
  margin-right: 0 !important;
}



/* chat_windows */

.chat_window {
  padding: 0px;
  position: relative;
}


.chat_window > .row {
  box-shadow: 0 0 5px rgba(0,0,0,0.5);
  border-radius: 6px;
}
.chat-users-list {
  border-right: 1px solid;
  min-height: calc(100vh - 70px);
  padding: 0;
  position: relative;
}
.chat-users-bulk {
  border-right: 1px solid;
  min-height: calc(50vh - 70px);
  padding: 0;
  position: relative;
}
.chat-users-list .user_list_header{
  padding: 10px 10px;
  background: #ededed;
}

.chat_search_box {
  /* background: #ffffff; */
  padding: 0px 10px;
}

.chat_all_users {
  height: calc(100vh - 310px);
  overflow-y: scroll;
  position: relative;
}


.chat-users-list .chat-user {
  cursor: pointer;
  padding: 10px 15px;
  border-bottom: 1px solid #ededed;
}

.chat-users-list .chat-user .user_name h4 {
  font-size: 16px;
  font-weight: 400;
  /* color: #000000; */
  margin-bottom: 5px !important;
}

.chat-users-list .chat-user  .user_name p {
  font-size: 14px;
  color: #7f7575;
  font-weight: normal;
  margin: 0;
}

.chat-users-list .chat-user .user_info svg {
  margin-top: 5px;
}

.Time {
  position: absolute;
  right: 0;
  bottom: 0;
}

.Time span {
  color: #818181;
  font-size: 10px;
}


.chat-users-list .chat-user.active .user_name h4, .chat-users-list .chat-user.active  .user_name p{
  color:#ffffff
}
.chat-users-list .chat-user.active .Time span {
  color: #ffffff;
  font-size: 12px;
}


.chat-section .chat-window {
  border: none;
  min-height: calc(100vh - 128px);
}

.chat-section .chat-window .chat-header {
  border-bottom: none;
  padding: 10px 10px;
  background-color: #ededed;
  color: #000;
  font-weight: 600;
}

.chat-section .chat-window .bot-right {
  background: #dcf8c7 !important;
  color: #000;
  border-radius: 20px !important;
  border-bottom-right-radius: 0 !important;
  text-align: left !important;
  width: 45%;
  padding: 20px !important;
}

.chat-section .chat-window .user-left {
  padding: 15px !important;
  border-radius: 20px !important;
  background: #ffffff !important;
  border-bottom-left-radius: 0 !important;
}

.chat-history {
  padding: 10px 10px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 172px);
}

/* .note-btn{
  font-size: 12px;
  height: 25px;
  letter-spacing: 0;
  position: absolute;
  right: 65px;
  text-transform: capitalize;
  font-weight: 600;
} */
.imgselected {
  border: 2px solid #9c0f29; /* Customize the border color or other styles */
}
.scrollable-div{
  max-height: 500px; /* Adjust the height as needed */
  overflow-y: auto;
}

.file-icon-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 3px;
}

.note-btn {
  background-color: transparent;
  padding: 0;
  width: 15px;
}

.chat-user.active .note-btn svg {
  color: white;
}

.assign-btn {
  padding: 5px;
}


 .assign-btn svg {
  margin-top: 0 !important;
}

.note-btn-top{
font-size: 12px;
height: 25px;
letter-spacing: 0;
/* position: absolute; */
/* right: 10px; */
text-transform: capitalize;
font-weight: 600;
}

.aa{
  /* font-family: cursive; */
    font-weight: 600;
}

.notes-item {
  border-bottom: 1px solid #eeeeee;
  margin: 5px 0;
}

.item-name , .item-msg{
  font-size: 15px;
}

.item-text {
  font-size: 12px;
  color: #6c757d;
}
button#dropdown-for-userby {
  font-weight: 600;
  position: relative;
  top: -2px;
}
form.chat-form.d-flex.d-block {
  background: #ededed;
  display: flex !important;
  align-items: center;
  height: 58px;
  justify-content: space-between;
  padding: 0 20px;
}

form.chat-form textarea {
  width: 90%;
  border: 1px solid #dee2e6 !important;
  border-radius: 6px;
  height: 40px;
  color: #000000;
  padding: 3px 9px !important;
}

form.chat-form button.border.as_btn.send {
  text-align: center !important;
  color: #000000 !important;
  justify-content: center;
}

form.chat-form .download_icon svg {
  font-size: 35px;
  padding-right: 5px;
  padding-top: 11px;
  margin-bottom: 0;
  color: #9e122a;
  font-weight: 800 !important;
}
label.radio-btn-dosh-form {
  background: #a41d30;
  color: #ffffff;
  border: none;
  border-radius: 6px !important;
  padding: 6px 4px !important;
}

.chat-search-field input.form-control.form-input{
  color:#000;
}

.chat-search-field input.form-control.form-input::placeholder {
  color: black;
}


/* header */

.header a.navbar-brand {
  background: #ffffff;
  height: 70px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  border-radius: 0px;
}


.navbar-light .navbar-nav .nav-link {
  color: white !important;
  padding: 23px 15px;
  position: relative;
  font-size: 15px;
  transition: all .3s linear;
  margin-left: 0;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #e8ba22 !important;
  background: transparent;

}

.navbar-light .navbar-nav .nav-link.active {
  color: #e8ba22 !important;
  background: transparent !important;

}
.navbar-light .navbar-nav .nav-link:before{
    content: "";
    position: absolute;
    width: 0;
    height: 3px;
    background-color:#e8ba22;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
}

.navbar-light .navbar-nav .nav-link.active:before, .navbar-light .navbar-nav .nav-link:hover:before {
  width: 35px;
}

/* feed_area */
.feed_area {
  position: relative;
  width: 100%;
  padding-top: 40px;
}

.feed_box.card {
  overflow: hidden;
  margin-bottom: 30px;
}
.feed_box.card .card-header {
  position: relative;
  padding: 0;
  width: 100%;
}

/* .feed_box.card .card-header .user_img {
  width: 100%;
  object-fit: cover;
  height: 250px;
  object-position: center;
  max-width: 100% !important;
} */

.feed_box.card .card-header  .trash_top {
  position: absolute;
  display: flex;
  top: 0;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: row-reverse;
  padding: 0;
}

.comment-Scroll .rounded-circle {
  width: 30px;
  height: 30px;
  max-width: 100% !important;
}


.feed_box.card .card-header .trash_top .serial_no {
  background: #e8ba22;
  color: #fff;
  margin-top: 8px;
  padding: 0 7px;
  border-radius: 0 15px 15px 0px;
  font-size: 14px;
  font-weight: 600;
  padding-right: 13px;
  line-height: 26px;
  left: 0;
}

.feed_box.card .card-header .trash_top  span.icon {
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 9px;
  margin-right: 7px;
  border-radius: 30px;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  margin-top: 10px;
}

.feed_box.card  .card-body .info h6 {
  font-size: 18px;
  color: #040c29;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 5px !important;
  display: block;
}

.feed_box.card  .card-body  .info>p {
  color: #767c90;
  font-size: 14px;
  margin-bottom: 5px;
}

.feed_box.card .card-body .text_description>p {
  color: #767c90;
  font-size: 14px;

}

.feed_box.card  .card-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-footer {
  --bs-card-cap-padding-y: 0px !important;
  --bs-card-cap-padding-x: 0rem !important;
  padding-bottom: 4px !important;

}

.feed_box.card  .text_description {
  height: 150px;
  overflow-y: auto;
  padding-right: 10px;
  margin-bottom: 10px;
}

.feed_box.card .card-footer .cursor-pointer{
  display: flex;
  font-size: 10px;
  align-items: center;
  font-weight: 600;
}

.feed_box.card .card-footer .cursor-pointer button{
  display: flex;
  font-size: 10px;
  align-items: center;
  font-weight: 600;
}

.feed_box.card .card-footer .cursor-pointer span {
  font-size: 10px;
  font-weight: 600;
}

.feed_box.card .card-footer .cursor-pointer svg {
  margin-right: 2px;
}

/* gita_qoute */
.gita_quote_heading {
  background: #fff;
  height: clamp(73px,73px,73px);
  align-items: center;
  box-shadow: 0 0 10px rgba(0,0,0,.2);

  display: flex;
}

.gita_quote_heading button.as_btn {
  height: 35px;
  background-color: #e8ba22;
  font-size: 12px;
  padding: 0px 7px;
  position: relative;
  margin-left: 0;
  margin-top: 0;
}

.gita_quote_heading .hd-list-f {
  color: #4b3b5a;
  margin: 0;
}


.admin_grid_table .table > thead tr th {
  background: #a21a2e;
  color: #ffffff;
  font-size: 14px;
  text-align: left !important;
  padding: 12px 15px;
  text-transform: capitalize;
  vertical-align: top;
  
}

.admin_grid_table table.table {
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 6px !important;
  background: #ffffff;
}
.admin_grid_table table.table tbody tr td {
  vertical-align: middle;
  color: #000000 !important;
  font-size: 14px;
  padding: 10px 15px;
  text-transform: capitalize;
  
}
.admin_grid_table.border-remove .table > thead tr th, .admin_grid_table.border-remove table.table tbody tr td{
    border-width: 0px !important;
} 


.admin_grid_table table.table tbody tr td:nth-child(2) {
  width: auto;
  margin-right: 30px !important;
}

.puja_booking_table .admin_grid_table table.table tbody tr td:nth-child(2) {
  width: 15%;
}


.admin_grid_table table.table tbody tr td:nth-child(6) {
  width:150px;

}

.notifiction_area .admin_grid_table table.table tbody tr td:nth-child(2) {
  width: auto;
  margin-right: 30px !important;
}

 .admin_grid_table table.table tbody tr td:nth-child(6) {
  width:auto;

}

.admin_grid_table table.table tbody tr td:last-child {
  width:150px;

}
select.astroWatch-select-hover.select_width.form-select {
  width: 250px;
}


select.astroWatch-select-hover.language_select.form-select {
  width: 150px;
}

.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: #a21a2e;
  border-color: #a21a2e;
}

/* aarti section */

.main.aarti_section {
  margin-left: 0;
  font-size: 28px;
  padding: 0;
}

.main.aarti_section .sidenav {
  height: calc(100vh - 200px);
  width: 100%;
  position: sticky;
  top: 9px;
  z-index: 1;
  background-color: #ffffff;
  overflow-x: hidden;
  padding-top: 0px;
  margin-top: 0px;
  padding: 0;
  box-shadow: 0 0 5px rgba(0,0,0,0.2);
  border-radius: 6px;
}

.main.aarti_section .sidenav a {
  padding: 10px 15px;
  text-decoration: none;
  font-size: 16px;
  color: #818181;
  display: block;
  margin: 0 !important;
  border-top: 1px solid #ededed;
}
.main.aarti_section .sidenav a.activel {
  background-color: #9d102a;
  color: #fff !important;
  border-radius: 4px;
  margin-left: 8px;
}


.aarti_inner .card .card-header {
  position: relative;
  width: 100%;
  border: none;
  padding: 0;
  background: transparent;
}

video.aarti_video {
  width: 100%;
  height: 235px;
  object-fit: cover;
}

.aarti_inner .card .card-header img {
  object-fit: cover;
  height: 235px;
}

.gallery-media .aarti_inner .card .card-header img {
  object-fit: cover;
  height: 330px;
  object-position: top;
}



.aarti_inner .card .card-header .icon {
  position: absolute;
  top: 0;
  right: 8px;
  background: #ffffff;
  border-radius: 30px;
  padding: 0 8px !important;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 8px;
  box-shadow: 0 0 5px rgba(0,0,0,0.5);
}

.icon1{
  position: absolute;
  top: 0;
  left: 8px;
}

.sortOrderSpan{
  width: auto;
  height: 21px;
  font-size: 12px;
}

.form-check-input:checked {
  background-color: #9d102a;
  border-color: #9d102a;
}
.aarti_inner .card .card-header .icon svg {
  width: 15px;
  height: 15px;
}


.height_box {
  min-height: 184px;
}

.height_box_reel {
  min-height: 201px;
}

span.user_issue {
  background: red;
  color: #ffffff;
  font-size: 11px;
  padding: 3px 5px;
  font-style: normal;
  border-radius: 30px;
  margin-left: 7px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.chat-users-list .custom-pagination {
  padding: 7px 0!important;
  width: 100% !important;
  position: absolute !important;
  bottom: 0;
  background-color: white;
}

.chat-users-list .custom-pagination .pagination {
  padding: 0 !important;
  margin: 0 !important;
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  float: inherit !important;
}

button.border.as_btn.send {
  width: auto;
  height: 40px;
  padding: 0 6px;
  padding-right: 15px;
}

.aarti_inner .card-text:last-child {
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.aarti_inner .card-text-reel:last-child {
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}


/* model design css 29-06-23*/
.modal-header {
  box-shadow: 0 0 10px rgba(0,0,0,.2);
  background-image: linear-gradient(343deg,#b2373b,#970625);
}





/* model design css 29-06-23_close*/


/* blog_section */

.blog_area  .flex-column.nav.nav-pills {
  height: calc(100vh - 200px);
  width: 100%;
  position: sticky;
  top: 9px;
  z-index: 1;
  background-color: #ffffff;
  overflow-x: hidden;
  padding-top: 0px;
  margin-top: 0px;
  padding: 0;
  box-shadow: 0 0 5px rgba(0,0,0,0.2);
  border-radius: 6px;
}

.blog_area .nav-pills .nav-link {
  border-radius: 0;
  padding: 10px 15px;
  text-decoration: none;
  font-size: 16px;
  color: #818181;
  display: block;
  margin: 0 !important;
  border-top: 1px solid #ededed;
}

.blog_area .nav-pills .nav-link.active {
  background-color: #9d102a !important;
  color: #fff !important;
  border-radius: 6px;
}

.blog_area .icon {
  bottom: 8px;
  position: absolute;
  right: 8px;
  background: #ffffff;
  border-radius: 30px;
  padding: 0 8px !important;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 5px rgba(0,0,0,0.5);
}

.blog_area .icon svg{
  width: 15px;
  height: 15px;
}

.download_icon {
  position: relative;
}

.download_icon  span.cross svg {
  font-size: 14px !important;
  padding: 0 !important;
}
.download_icon span.cross {
  position: absolute;
  right: -8px;
  top: -6px;
}

.blog_area .card-img-top {
  width: 100%;
  min-width: 120px;
  height: 250px;
  margin-bottom: 0;
  object-fit: cover;
  object-position: top;
}

.blog_area a.default-link-style {
  text-decoration: none;
}

.blog_area .blog-cards .card-body p {
  /* display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 60px; */
}

.blog_area form.add_blog_form {
  box-shadow: 0 0 5px rgba(0,0,0,0.2);
  border-radius: 6px;
  background: #ffffff;
  padding: 20px;
}

.blog_details img.w-100.blog_height {
  height: 500px;
  object-fit: contain;
}
.blog-cards.card {
  border: none;
}




.gita_quote_heading .form-select {
  height: 40px !important;
  padding: 0px 15px !important;
}

.header .nav-link.active, 
.header .nav-item.show .nav-link {
  color: white !important;
  background-color: #e8ba223b !important;
}

button.reply.as_btn.ms-0 {
  text-transform: capitalize;
}


/* notifiction_area */

.notifiction_area {
  min-height: calc(100vh - 70px);
}


.notifiction_form {
  margin-top: 40px;
  background: rgb(255 255 255 / 65%);
  padding: 25px;
  border-radius: 6px;
}

label{
  font-size: 16px;
  color: #979ba7;
  margin-bottom: 8px;
  text-transform: capitalize;
}

.form-control, .form-select {
  height: 40px;
  outline: none;
  box-shadow: none!important;
  background-color: #fff;
  color: #000;
  /* padding: 0 10px; */
  text-align: left;
  border: 1px solid #b3b3b3;
  font-size: 16px;
}
.form-select {
  padding: 0 15px !important;
  height: 40px;
  box-shadow: none !important;
  text-transform: capitalize;
  margin-left: 0 !important;
  cursor: pointer;
  text-align: left;
  font-size: 16px;
}
.notifiction_area .tableWc {
  padding: 5px;
  padding-bottom: 35px;
  background: rgb(255 255 255 / 65%);
}

.fc-button {
  text-transform: capitalize !important;
}

/* redesign_css */

.homepage {
  min-height: calc(100vh - 70px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.homepage img {
  width: 300px;
}

.Reels_video .height_box_reel {
  min-height: 160px;
  padding: 10px;
  padding-top: 0;
}

button.navbar-toggler.collapsed {
  color: #ffffff !important;
  margin-right: 15px;
  border: 1px solid white;
}

.user_comment .responsiveSize.name {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 13px;
  width: 60px;
}

.user_comment .responsiveSize.date {
  font-size: 12px !important;
}

svg.me-2.cursur {
  cursor: pointer;
}

svg {
  cursor: pointer;
}

.as_login_area button.as_btn {
  padding: 0px 20px;
  height: 35px;
}

hr.dropdown-divider {
  display: none;
}

.header .dropdown-menu.show {
  border: none;
  box-shadow: 0 2px 10px rgba(0,0,0,0.3);
  border-radius: 6px;
  left: auto;
  right: 0px;
}

.header a.dropdown-item {
  color: #767c90;
  font-size: 15px;
}

.header .btn {
  color: #767c90;
  background-color: #dcdcdc;
  border: none;
  --bs-btn-padding-y: 0.2rem !important;
  margin-top: 10px;
}

.header .dropdown-menu.show a {
  color: #4b3b5a;
  text-decoration: none;
}
.header .dropdown-menu.show a.active {
  color: #ffffff;
  background-color: #ab262d;
}

hr {
  margin: 0.4rem 0;
  opacity: 0.15;
}

.react-bootstrap-table {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.modal .chat_all_users {
  height: calc(100vh - 305px);
  overflow-y: scroll;
  position: relative;
  margin-top: 20px;
}

.modal .chat_all_users .chat-user {
  cursor: pointer;
  padding: 10px 5px;
  border-bottom: 1px solid #ededed;
}

.modal .chat_all_users .user_name p {
  margin-bottom: 0;
}


.modal .chat_all_users .user_name input {
  margin-right: 6px;
}


.blog-cards .heading_18 {
  font-size: 18px;
  color: #040c29;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 5px !important;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 58px;
  line-height: 28px;
}

article.my-2.artical_scroll {
  height: 260px;
  overflow-y: scroll;
}

.custom-margin .user_img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.mandir_sidebar_tabs {
  height: calc(100vh - 200px);
  width: 100%;
  position: sticky;
  top: 9px;
  z-index: 1;
  background-color: #ffffff;
  overflow-x: hidden;
  padding-top: 0px;
  margin-top: 0px;
  padding: 0;
  box-shadow: 0 0 5px rgba(0,0,0,0.2);
  border-radius: 6px;
}


.mandir_sidebar_tabs .nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
  padding: 10px 15px;
  text-decoration: none;
  font-size: 16px;
  color: #818181;
  display: block;
  margin: 0 !important;
  border-top: 1px solid #ededed;
}

.mandir_sidebar_tabs .nav-pills .nav-link.active, .mandir_sidebar_tabs .nav-pills .show > .nav-link {

  background-color: #9d102a !important;
  color: #fff !important;
  border-radius: 4px;
  margin-left: 8px;
}

.header a.dropdown-item:active {
  color: white !IMPORTANT;
}

img.quotes_table_preview_image {
  width: auto;
  height: 55px;
  object-fit: contain;
}

.chat_window a {
  line-break: anywhere;
}

.mlist{
  margin:1rem;
}

.mlist-1{
  margin: 1rem;
}

.label-selected-menu {
  width: 100% !important;
  height: 40px !important;
  padding: 0 10px!important;
  box-shadow: none !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  padding: 0 9px !important; 
  border: 1px solid #b3b3b3;
}

.Multiple_button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Multiple_button button.as_btn {
  height: 26px;
  background-color: #e8ba22;
  font-size: 13px;
  text-transform: capitalize;
  display: inline-flex;
  align-items: center;
  /* padding: 0 23px; */
  position: relative;
  margin-left: 15px;
  border: none!important;
  outline: none;
  width: auto;
  transition: .2s linear;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 500;
}

.chat-user .btn-primary.m-0 {
  position: absolute;
  right: 10px;
  text-transform: capitalize;
  font-size: 12px;
  letter-spacing: 0;
  height: 25px;
}

button.btn.as_btn.ms-2.btn.btn-primary {
  /* text-transform: capitalize; */
  text-wrap: nowrap;
}


.date-range-wrapper {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.select-range {
  width: 100%;
  background-color: #ffffff;
  appearance: none;
  height: 38px;
  padding: 0px 9px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}
.select-range:focus-visible{
  outline: none;
  border: none;
}

img.select-icons {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 11px;
}
.select-icon-wrapper {
    position: relative;
    padding: 0px;
    height: 40px;
    border: 1px solid #b3b3b3 !important;
    padding: 0px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

/* .select-range, .date-range-wrapper input, .select-icon-wrapper  { */
 .date-range-wrapper input {
  height: 40px;
  border: 1px solid #b3b3b3 !important;
  font-size: 16px;
  border-radius: 5px;
  padding: 0 9px;
  width: 100%;
  background-color: #ffffff;
}

.date-range-wrapper .react-datepicker-wrapper{
  width: 100%;
  line-height: 15px;
}

.date-range-wrapper  .react-datepicker__day--in-range, .date-range-wrapper  .react-datepicker__day--in-selecting-range{
  background-color: #e8ba22 !important;
}

.puja-recommendation .admin_grid_table table.table tbody tr td:nth-child(2) {
  width: 20%;
}

.puja-booking-modal h5{
  font-size: 18px;
  line-height: 20px;
}
.puja-booking-modal  p {
  font-size: 16px;
  line-height:20px;
}

/* responsive last on last please */


.quotes_preview_image {
  width: 200px;
  height: auto;
  margin-top: 10px;
}

.quotes_table_preview_image {
  width: 80px;
  height: auto;
}

.page_heading {
  background: #fff;
  height: clamp(73px,73px,73px);
  align-items: center;
  box-shadow: 0 0 10px rgba(0,0,0,.2);

  display: flex;
}

.page_heading button.as_btn {
  height: 40px;
  background-color: #e8ba22;
  font-size: 13px;
  padding: 0px 12px;
  position: relative;
  margin-left: 0;
 
}

.page_heading .hd-list-f {
  color: #4b3b5a;
  margin: 0;
}

.puja_bookings_table .admin_grid_table table.table tbody tr td:nth-child(2) {
  width: auto;
  margin-right: inherit;
}


.puja_bookings_table .admin_grid_table table.table tbody tr td:nth-child(6) {
  width: auto;
}

.master_god .god-day {
  color: #25658f;
}

.master_god .god-image-grid{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
  gap: 10px;
}

.master_god .god-image-grid img{
  width: 100%;
  /* height: auto; */
  object-fit: cover;
}

.preview-image-input {
  display: block;
  position: relative;
  cursor: pointer;
}

.preview-image-input .image-preview img {
  height: 160px;
  width: auto;
}

.preview-image-input input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.preview-image-input .image-preview {
  position: relative;
  display: inline-block;
  margin-right: 10px;
}

.preview-image-input .image-preview .remove-button {
  position: absolute;
  top: 5%;
  right: 5%;
  font-size: 26px;
  color: red;
  padding: 2% 4%;
  cursor: pointer;
  z-index: 1000;
}

.redstar {
  color: red;
}

.god-card-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.Reels_video .heading_18 {
  font-size: 18px;
  color: #040c29;
  font-weight: 600;
  text-transform: capitalize;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 43px;
  margin-bottom: 10px !important;
  text-align: left;
}



.is-invalid-Q{
  border-color: rgb(172, 170, 170) !important;
  background-image: none !important
}
.border_checkbox_c{
border-color:#a21a2e ;
}

.errorVal{
color:#dc3545;
font-size:0.875em;
/* font-weight:420; */
}

.cross_icon {
  font-size: 22px;
}

.link_copy_cs{
font-size:8px ;
color:blue ;
word-break:break-word;
}
.link_copy_cs_puja{
  font-size:12px ;
  color:blue ;
  word-break:break-word;
  }

.text-danger {
  font-size: 0.875em !important;
}

/* .react-datepicker__input-container{
  width: 160% !important;
} */


.react-datepicker__input-container{
  width: 100% !important;
}


.react-datepicker__input-container-1{
  width: 100% !important;
}

.react-datepicker__input-container .fil-date-pick {
  width: 60% !important;
}
.custom-datepicker {
  font-size: 12px; /* Change this to your desired font size */
}

.custom-datepicker input {
  font-size: 12px; /* Ensure the input field has the same font size */
}

.st-in-bdr-clr{
 border: 1px solid #b7afaf !important;
}
.form-ch{
  height: 40px !important;
  font-size: 16px !important;
  /* width: 60% !important; */
}
.label-f-w-1{
  width: 13% !important;
  font-size: 16px !important
}

.label-f-w{
  width: 8% !important;
  font-size: 16px !important
}
.label-f-w-cd{
  /* width: 8% !important; */
  font-size: 16px !important
}
.template-error{
  font-size: .875em;
    margin-top: 0.25rem;
    width: 100%;
    color: #dc3545;
}

.w-20{
  width: 20% !important;
}
.w-80 {
  width: 80%;
}

.mg-footer{
  --bs-card-cap-padding-x: 1rem !important;
}

.as_btn.bts{
  padding: 7px;
  font-size: 12px;

}

.column-container {
  display: flex;
}

.list-column {
  flex: 1;
}

.list-group-item {
  margin-bottom: 10px; /* Adjust as needed */
}
.userD-list{
  list-style-type: none;
}
span.status_success{
  background: green;
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
}

span.status_failed {
  background: red;
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
}


span.status_pending {
background: #e8ba22;
color: white;
padding: 5px;
border-radius: 5px;
font-size: 12px;
}

.code-num {
  /* display: flex; */
  position: relative;
  /* border: 1px solid gray; */
  border-radius: 5px;
}

.code-num label {
  display: none;
}
.phn-code {
  position: absolute;
  /* left: 6px; */
  width: 102px;
  /* border-radius: 18px 7px 7px 0px; */
  /* border-right: 1px solid gray; */
  padding: 0 0px 0px 10px;
}

.phn-code select {
  /* border: none !important; */
  padding: 0px 6px !important;
  border-radius: 6px 0px 0px 6px;
  border-right: none !important;
}
.phn-num {
  width: 100%;
  padding-left: 102px;
}
.puja_booking_modal .phn-num {
  padding-left: 95px;
}
.phn-num input {
  border-radius: 0px 5px 5px 0;
}

.country_symbol .phn-code .css-13cymwt-control, .country_symbol .phn-code .css-t3ipsp-control, .country_symbol .phn-code {
  width: 125px !important;
}

.country_symbol .phn-num {
  padding-left: 125px !important;
}
.country_symbol .phn-num .form-control{
  border-left: 0px;
}

.country_symbol .react-select__menu {
  width: auto;
} 

.country_symbol .phn-code {
  padding: 0px 0px 0px 0px;
}


/* user list search */
.user-search .label-f-w-cd {
  font-size: 16px !important;
  margin-bottom: 0;
}

.user-search .form-ch {
  height: 40px !important;
  border-radius: 6px;
  border: 1px solid #b3b3b3;
}

.user-search .form-control:focus, .form-select:focus {
  background-color: #ffffff;
  border: 1px solid #b3b3b3;
}



.user-list-table {
  margin: 0 1rem;
}

.user-list-table .container {
  width: 100%;
  max-width: 100%;
}


/* feeds */
.feed_user_img {
  padding: 1rem 1rem 1rem 1rem;
  display: flex;
  justify-content: start;
  align-items: center;
  column-gap: 15px;
}
.feed_box.card .card-header .user_img {
  object-fit: cover;
  height: 70px;
  width: 70px;
  object-position: center;
  max-width: 100% !important;
  border-radius: 50%;
  box-shadow: 1px 2px 2px 2px #cbc5c5;
} 

.feed-user-info {
  padding-top: 1rem;
  max-width: 70%;
}

.feed_box.card .feed-user-info h6 {
  font-size: 18px;
  color: #040c29;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 0px !important;
  display: block;
}
.feed_box.card .feed-user-info p {
  color: #767c90;
  font-size: 14px;
  margin-bottom: 5px;
}



.whatsapp-modal{
  max-width: 80%;
}
.whatsapp-modal form, .whatsapp-modal .modal-body  {
  height: 100%;
}

.whatsapp-modal .scrollable-div {
  max-height: 80vh;
}

.custom-card {
  height: 150px; /* Adjust this value as needed */
  overflow: hidden; /* Optional: to handle overflow content */
}


.add-puja-modal{
  height: 220px !important;
}

.add-puja-modal .card-header img{
  height: 220px !important;
  object-fit: fill !important;
}



.puja_booking-wrapper table thead tr th{
    vertical-align:top;
    text-transform: capitalize;
}
.puja_booking-wrapper .chat_search_box{
  padding: 20px;
}


.puja_booking-wrapper .chat_search_box{
  font-size: 14px;
  color: #000000;
}
/* .puja_booking-wrapper table td a{
  color: #000000;
} */
label {
  color: #000000;
}

.chat-search-field .form-input {
  border-radius: 6px;
  height:40px;
}
.label-selected-menu__control {
  height: 40px;
  border-radius: 6px !important;
  border: 1px solid #b3b3b3 !important;
}


.react-datepicker__input-container .fil-date-pick {
  width: 100% !important;
}


.box-shadow{
  box-shadow:0 0 5px rgba(0,0,0,0.5) !important;
}
.p-20{
  padding: 20px;
}
.react-datepicker-wrapper {
  display: block !important;
}

.br-6{
  border-radius: 6px;
}
.w-1920{
  width: 1920px;
}


.lh-20{
  line-height: 20px;
}

.add-btn1{
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  padding: 10px 15px;
  border: none !important;
}


.form-check-label, 
.form-check-input{
  cursor: pointer;
}


.btn-success1 {
  color: #fff;
  background-color: #198754;
}


.userlist-calender .react-datepicker__input-container .react-datepicker__calendar-icon {
  top: 50%;
  transform: translateY(-50%);
}

.userlist-calender .react-datepicker__view-calendar-icon input {
  padding-left: 30px;
}

.paid-option {
  color: green;
  font-weight: 700;
}

.notes-btn, .note-btn-top {
  background-color:  #e5672a;
  color: white;
}

.blocked-user-btn {
  width: auto;
  height: 25px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  margin-top: 0px;
}
.edit-btn1{
  background-color: #808080 !important;
}


.add-puja-gallery .add-puja-modal .card-header img {
    margin-bottom: 0px !important;
}

.font_20{
  font-size: 20px;
}

.font_18{
  font-size: 18px;
}


.font_16{
  font-size: 16px;
}


.font_14{
  font-size: 14px !important;
}
::placeholder{
font-size: 14px;
}


.add-puja-gallery .add-puja-modal{
  height: 268px !important;
}

.card-file-name {
  padding: 0 10px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  height: 30px;
  overflow: hidden;
  width: 100%;
  margin-top: 10px;
}

input{
  height: 40px;
  font-size: 16px;
  line-height: 20px;
  border: 1px solid #b3b3b3;
}

.h-70{
  height: 70%;
}
.react-select__control {
  height: 40px;
  line-height: 40px;
  padding: 0;
  border-color: #b3b3b3 !important;
  font-size: 16px;
}

.react-select__value-container {
  padding: 0px 8px !important;
  flex-wrap: nowrap !important;
}

.react-select__input-container {
  margin: 0px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  cursor: pointer;
}

.react-select__multi-value {
  margin: 0px;
  height: 30px;
  align-items: center;
} 

.shadow-none{
  box-shadow:  none !important;
}

.invalid-feedback{
  font-size: 14px;
}

.paginate-custom {
  display: flex;
  align-items: center;
  padding: 0;
  border-radius: 6px;
  background-color: #ffffff;
  margin-right: 15px;
  border: 1px solid #a9a5a5;
  overflow: hidden;
}
.paginate-custom .page-item.active {
  background-color: #a21a2e;
  color: #ffffff;
}
.paginate-custom .page-item.active .page-link{
  color: #ffffff;
}
.paginate-custom .page-item {
  list-style-type: none;
  padding: 10px 15px;
  border-right: 1px solid #a9a5a5;
}
.paginate-custom .page-item.disabled{
  background-color: #dcdcdc;
}

.paginate-custom .page-item:last-child {
  border-right: none;
}
.paginate-custom li.disabled .page-link{
  color: #838383;
  cursor: not-allowed;
} 
.paginate-custom .page-item .page-link {
  text-decoration: none;
  color: #000;
}

.reset-btn{
  background-color: gray !important;
  color: #ffffff !important; 
}
.test-btn{
  background-color: #e88722 !important;
}

.download-btn {
  background-color: #a21a2e !important;
  color: #ffffff !important;
}
.syncdownload-btn{
  background-color: green !important;
  color: #ffffff !important;
}

.w-30{
  width: 30%;
}

.w-70{
  width: 70%;
}

.counter-wrapper {
  padding: 10px 0;
}

.counter-wrapper ._btn   {
  display: flex;
  flex-wrap: nowrap;
}

.counter-top {
  font-size: 18px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  row-gap: 3px;
}
.counter-top small {
  font-size: 22px;
  font-weight: 700;
  color: #ab2a35;
}

.contact_detail_question {
  padding: 2px 0px;
  width: auto;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}


.submit_modal .modal-dialog{
  backdrop-filter: blur(2px);
}
.submit_modal .modal-content {
  box-shadow: 1px 2px 3px 1px #cccccc;
}

.submit-error {
  font-size: 14px;
  color: red;
  font-style: italic;
}

.modal{
  padding-right: 0px !important;
}

.disabled-btn {
  filter: opacity(0.3);
}


.navbar-nav .nav-link.logout.active  {
  color: white !important;
  padding: 23px 15px;
  position: relative;
  font-size: 15px;
  transition: all .3s linear;
  margin-left: 0;
}


.navbar-nav .nav-link.logout.active:hover   {
  color:#e8ba22 !important
}
.navbar-light .navbar-nav .nav-link.logout:before {
  opacity: 0;
}

.navbar-light .navbar-nav .nav-link.logout:hover::before {
  opacity: 1;
}

.react-select__option{
  font-size: 16px !important;
}
.react-select__option:hover, .react-select__option--is-focused{
  background-color:#1967d2 !important;
  color: #ffffff !important;
}

.react-datepicker-wrapper {
  line-height: 30px;
}



.booking_id_wrapper .d-flex {
  border-bottom: 2px solid #a2a0a0;
  margin-bottom: 15px;
  flex-wrap: wrap;
}


@media only screen and (min-width:1900px) {
  .w-1920{
    width:100%;
  }

}

@media only screen and (max-width:1599px) {
  .w-1920{
    width: 1650px;
  }
  .puja_booking-wrapper .w-1920 {
    width: 1920px;
  }
  .modal-title {
    font-size: 20px;
}
.modal-header {
  padding: 10px 15px;
}
.navbar-light .navbar-nav .nav-link {
  padding: 23px 10px;
  font-size: 15px;
}
.header a.dropdown-item{
  font-size: 14px;
}
.puja-recommendation  .admin_grid_table table{
  width: 100%;
}


}

@media only screen and (max-width:1399px) {
  .user-list-table .table {
    /* width: 1600px; */
    width: 1285px;

}
.user-list-table .admin_grid_table .table > thead tr th {
  font-size: 13px;
}

.w-1920{
  width: 100%;
}
.puja_booking-wrapper .w-1920 {
  width: 1920px;
}

.user-search .label-f-w-cd, .label-f-w-1, label, .label-f-w, .label-f-w-cd, .form-ch, .react-select__control, .select-range,  .date-range-wrapper input {
  font-size: 14px !important;
}
.p-20 {
  padding: 15px;
}

/* ::placeholder
{
font-size: 12px;
} */

.font_20{
  font-size: 18px;
}

.font_18{
  font-size: 16px;
}


.font_16{
  font-size: 14px;
}


.font_14{
  font-size: 12px;
}
.invalid-feedback{
  font-size: 12px;
}

.puja-booking-modal  p {
  font-size: 14px;
}

.form-control, .form-select, label, .react-select__option{
  font-size: 14px !important;
}
.card-file-name {
  padding: 0 8px;
}

.label-selected-menu {
  width: 100% !important;
  height: 39px !important;
  /* padding: 0 28px!important; */
  box-shadow: none !important;
  font-size: 13px !important;
  font-weight: 400 !important;
}
video.aarti_video {
width: 100%;
height: 165px;
object-fit: cover;
}

.blog_area .card-img-top {
width: 100%;
min-width: 120px;
height: 225px;
margin-bottom: 0;
object-fit: cover;
}
.chat-users-bulk .form-select {
font-size: 13px !important;
font-weight: 500 !important;
}



.navbar-light .navbar-nav .nav-link {
  padding: 23px 7px;
  font-size: 14px;

}




}

@media only screen and (max-width: 1199px) {
.blog_area .card-img-top {
  width: 100%;
  min-width: 120px;
  height: auto;
  margin-bottom: 0;
  object-fit: fill;
}
.blog_details img.w-100.blog_height {
  height: auto;
  object-fit: cover;
}

.blocked-user-btn{
  background-color:#b2373b !important;
  color: white;
  border: none;
  border-radius: 5px;
  --bs-btn-padding-y: 0.5rem !important;
  margin-top: 10px;
}

.unblocked-user-btn{
  background-color:#deeef9 !important;
  color: #000 !important;
}

.blocked-column-width{
  width: 20px !important;
  font-weight: 500;
  text-align: center !important;
}

.aarti_inner .card .card-header img {
  object-fit: cover;
  height: 170px;
}
.blog_area .card-img-top {
  width: 100%;
  min-width: 120px;
  height: 190px;
  margin-bottom: 0;
  object-fit: cover;
}

.react-bootstrap-table table.table.table-striped.table-hover.table-bordered {
  width: 1140px;
}

.navbar-light .navbar-nav .nav-link {
  padding: 4px 7px;
}
.nav-item.dropdown {
  margin-left: 0 !important;
}
.navbar-light .navbar-nav .nav-link:before {
  left: 3%;
}

.puja-recommendation .admin_grid_table table{
  width: 1250px;
}

.gita_quote_heading button.as_btn {
  height: 30px;
  font-size: 10px;
  padding: 0px 5px;
}


}

@media only screen and (max-width: 991px) {

  .w-md-75 {
      width: 75% !important;
  }
  
  
    .blog-cards .heading_18 {
      font-size: 16px;
     
      height: 60px;
  }
  
  /* .gita_quote_heading .d-flex.align-items-center.justify-content-between {
    flex-wrap: wrap;
  } */
  
  .gita_quote_heading ._btn.d-flex.w-50.justify-content-end {
    width: 100% !important;
    justify-content: start !important;
  
  }
  
  .gita_quote_heading {
    height: clamp(65px,65px,65px);
  }
  .gita_quote_heading.as_noti_head {
    height: clamp(100px,100px,100px);
  }
  
  .aarti_inner .card .card-header img {
    object-fit: cover;
    height: 170px;
  }
  .campaign table.table.table-striped.table-hover.table-bordered {
    width: 900px;
  }
  
  .custom-pagination .pagination>li>a {
    font-size: 10px;
  
  }
  
  .custom-pagination .pagination .previous a {
    font-size: 10px;
  
  }
  
  .navbar-light .navbar-nav .nav-link {
    padding: 4px 15px !important;
  
  }
  .ms-auto.nav-item.dropdown {
    margin-left: 0 !important;
  }
  .navbar-expand-xl .navbar-collapse {
    justify-content: end;
  }
  
  .navbar-light .navbar-nav .nav-link:before {
  display: none;
  }
  
  button.navbar-toggler {
    margin-right: 15px;
  }
  .gita_quote_heading button.as_btn {
    height: 30px;
    font-size: 10px;
    padding: 0px 7px;
  }
  
  
  .counter-wrapper {
    -webkit-column-gap: 20px!important;
    column-gap: 20px!important;
    justify-content: start;
    width: 1024px;
  }
  .font_20 {
    font-size: 16px;
  }
  
  
}
  
  
@media only screen and (max-width: 767px) {
  
    .as_login_area .login_inner {
      width: 92%;
     
  }
  
  .img-fluid-log {
    max-width: 50% !important;
  }
  
    .chat-section {
      position: absolute;
      top: 0;
      width: 100%;
    }
  
  
    .blog-cards .heading_18 {
      font-size: 16px;
      height: auto;
      overflow: auto;
      display: block !important;
  }
  
    .campaign table.table.table-striped.table-hover.table-bordered {
      width: 700px;
  }
  
    .aarti_inner .card .card-header img {
      object-fit: cover;
      height: auto;
  }
  
    .gita_quote_heading {
      height: clamp(78px,78px,78px);
  }
  
  .gita_quote_heading button.as_btn {
      height: 34px;
      background-color: #e8ba22;
      /* font-size: 13px; */
      /* padding: 0px 14px; */
      position: relative;
      margin-left: 0;
      margin-top: 0;
  }
  
  .form-select {
    width: 100% !important;
    /* margin-top: 15px; */
  }
  
  .blog_area .flex-column.nav.nav-pills {
    height: auto;
    width: 100%;
    position: sticky;
    top: 9px;
    z-index: 1;
    background-color: #ffffff;
    overflow-x: hidden;
    padding-top: 0px;
    margin-top: 0px;
    padding: 0;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
    border-radius: 5px;
  }
  
  .blog_area .card-img-top {
    width: 100%;
    min-width: 120px;
    height: auto;
    margin-bottom: 0;
    object-fit: contain;
  }
  
  
  .react-select__option{
    font-size: 14px !important;
  }

}

.not-auth {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 70px );
  font-size: 31px;
  font-weight: 500;
}

/* .phn-code .css-hlgwow {
  padding: 2px 2px !important;
} */

.phn-code  .css-1sa5id3-MenuList {
  max-height:auto !important;
  overflow-y: inherit !important;

}
.phn-code .css-13cymwt-control, .phn-code .css-13cymwt-control, .phn-code .css-t3ipsp-control{
  min-height: 40px !important;
  cursor: pointer;
  height: 40px;
  width: 110px;
}

.phn-code .react-select__control{
  min-height: 55px !important;
  cursor: pointer;
}

.phn-code  .react-select__dropdown-indicator {
  padding: 5px !important;

}

/* .phn-code  .css-1fdsijx-ValueContainer {
  padding: 2px 4px !important;
} */
.puja_booking_modal .phn-num {
  padding-left: 115px;
}

.code-num .phn-code .css-13cymwt-control, .phn-code .css-13cymwt-control, .phn-code .css-t3ipsp-control {
  min-height: 40px !important;
  cursor: pointer;
  height: 40px;
  width: 94px;
}


.color_box {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 7px;
  border-radius: 50%;
}

.line_clamp1{
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.dashboard_table .admin_grid_table table.table tbody tr td:last-child {
  min-width: 250px;
}

.puja_width {
  display: inline-block;
  width: 300px;
}

.plan_image {
  object-fit: fill !important;
}